import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="main-body">
            <div className="contact-title-container">
                <h1 className="contact-title">Contact Us</h1>
            </div>

            <div className="contact-form-container">
                <div className="contact-form">
                    <div className="contact-field">
                        <label htmlFor="email" className="contact-label">Email</label>
                        <input type="email" id="email" placeholder="Enter your email address" className="contact-input" />
                    </div>
                    <div className="contact-field">
                        <label htmlFor="name" className="contact-label">Name</label>
                        <input type="text" id="name" placeholder="Enter your name" className="contact-input" />
                    </div>
                </div>
                <button className="contact-submit">Contact Us</button>
            </div>
        </div>
        
    );
}

export default Contact;
