import React from 'react';
import { HashRouter as Router, Route, Routes} from "react-router-dom";
import Header from './components/Header';
// import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Work from './pages/Work';
import Contact from './pages/Contact';


import './App.css';

function App() {
  // Call the function to clear localStorage on browser unload
  // clearLocalStorageOnUnload(); 
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/work" element={<Work />} />
          </Routes>
        {/* <Footer /> */}
      </Router>
    </div> 
  );
}

export default App;
